import styled from "@emotion/styled"
import React from "react"
import AppWrapper from "../styles/AppWrapper"
import H1 from "./H1"
import ReactHtmlParser from "react-html-parser"

const Section = styled.section``

const Heading = ({ headingText }) => {
    return (
        <>
            <Section>
                <AppWrapper>
                    <H1>{ReactHtmlParser(headingText)}</H1>
                </AppWrapper>
            </Section>
        </>
    )
}
export default Heading
