import styled from "@emotion/styled"
import React from "react"
import ReactHtmlParser from "react-html-parser"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"

const SingleBio = ({ section, bio }) => {
    return (
        <>
            <BioWrapper>
                <ParagraphDiv>{ReactHtmlParser(bio)}</ParagraphDiv>
            </BioWrapper>
        </>
    )
}

export default SingleBio

const BioWrapper = styled.div``
const ParagraphDiv = styled.div`
    p {
        font-size: 18px;
        color: ${props => props.textColor || colors.black};
        line-height: 1.8;
        z-index: 9;
        font-family: ${fontFamily.manrope};
        font-weight: 500;
        max-width: 700px;
        padding: 15px 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-bottom: 0;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    a {
        text-decoration: none;
        color: ${colors.purple};
    }
`
