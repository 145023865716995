import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/Heading"
import Biography from "../components/About/Biography"

const AboutPage = ({ data }) => {
    const { heading, image: profileImage } = get(
        data,
        "allBiographyJson.edges[0].node",
        {}
    )
    const { copy: headingCopy } = heading
    const { biography: biographyFormat } = get(
        data,
        "allBiographyJson.edges[0].node",
        {}
    )

    console.log("profileImage", profileImage)

    return (
        <Layout>
            <SEO
                title="About | Johnnie Aiello"
                description="A taste for who Johnnie Aiello is and how he's gotten to where he is today."
            />
            <Heading headingText={headingCopy.header} />
            <Biography biography={biographyFormat} image={profileImage} />
        </Layout>
    )
}

export default AboutPage

export const query = graphql`
    query {
        allBiographyJson {
            edges {
                node {
                    heading {
                        copy {
                            header
                        }
                    }
                    image {
                        src {
                            childImageSharp {
                                fluid(maxWidth: 900, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        alt
                    }
                    biography {
                        id
                        bio
                    }
                }
            }
        }
    }
`
