import styled from "@emotion/styled"
import React from "react"
import { device } from "../../styles/device"
import AppWrapper from "../../styles/AppWrapper"
import SingleBio from "./SingleBio"
import Img from "gatsby-image"

const BioSection = styled.section`
    margin: 48px 0;
`

const AppWrapperBios = styled(AppWrapper)`
    grid-template-columns: 1fr;
    grid-gap: 48px;
    display: grid;

    @media ${device.tablet} {
        grid-template-columns: 380px 1fr;
        grid-gap: 8%;
    }
`

const BioImage = styled(Img)`
    @media ${device.tablet} {
        max-height: 567px;
    }
`

const Biography = ({ biography, image }) => {
    return (
        <BioSection>
            <AppWrapperBios>
                <BioImage
                    fluid={image.src.childImageSharp.fluid}
                    alt={image.alt}
                />
                <SingleBio key={biography.id} bio={biography.bio} />
            </AppWrapperBios>
        </BioSection>
    )
}

export default Biography
